<script>
import RaiEmployeeSelect from "@/components/util/RaiEmployeeSelect.vue";
import RaiDatePicker from "@/components/util/RaiDatePicker.vue";
import RaiTimePickerText from "@/components/util/RaiTimePickerText.vue";
import ShiftDialogEventList from "@/components/daybook/ShiftDialogEventList.vue";
import ShiftDialogAddEvent from "@/components/daybook/ShiftDialogAddEvent.vue";

import { injectActiveEmployee } from "@/mixins/employee";
import { hasGlobalLoaderAccess, hasSnackbarAccess } from "@/mixins/ui";

import ApolloCacheUpdater from "apollo-cache-updater";

import { format, isBefore, parseISO, isValid } from "date-fns";

import { SHIFTS_TODAY } from "@/graphql/shift/queries.gql";
import { DELETE_SHIFT_EVENT } from "@/graphql/shift_event/mutations.gql";

const loadingKey = "edit-shift";

export default {
  name: "ShiftForm",
  components: {
    RaiEmployeeSelect,
    RaiDatePicker,
    RaiTimePickerText,
    ShiftDialogEventList,
    ShiftDialogAddEvent,
  },
  mixins: [injectActiveEmployee, hasGlobalLoaderAccess, hasSnackbarAccess],
  props: {
    shift: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isDetailed: false,
      triggerUpdate: 0,
      newEvents: [],
      updatedEvents: [],
    };
  },
  computed: {
    test() {
      return isValid(parseISO("2022-11-11"));
    },
    loading: {
      get() {
        return this.isLoading(loadingKey);
      },
      set(v) {
        return false;
      },
    },
    isWIWShift() {
      return !!this.shift.whenIWorkId;
    },
    // Should not be able to edit the employee or time of a shift if:
    // - The employee is not a manager
    // - The shift is a WIW shift
    notManagerOrIsWIWShift() {
      return [!this.activeEmployeeCan.seeManagerContent, this.isWIWShift].some(
        Boolean
      );
    },
    computedShiftEvents() {
      return [...this.shift.events, ...this.newEvents]
        .filter((e) => e.startAt && e.endAt && e.title)
        .sort((a, b) => {
          if (isBefore(parseISO(a.startAt), parseISO(b.startAt))) return -1;
          if (isBefore(parseISO(b.startAt), parseISO(a.startAt))) return 1;
          return 0;
        });
    },
    shiftInputObject() {
      return {
        input: {
          id: this.shift.id,
          employeeId: this.shift.employee?.id,
          businessDate: this.shift.businessDate,
          startTime: this.shift.scheduledStartAt,
          endTime: this.shift.scheduledEndAt,
          notes: this.shift.notes,
          createEvents: this.newEvents,
          updateEvents: this.updatedEvents,
        },
      };
    },
    disabled() {
      return (
        !this.shift.employee?.id ||
        !this.shift.businessDate ||
        !this.shift.scheduledStartAt ||
        !this.shift.scheduledEndAt
      );
    },
  },
  mounted() {
    if (Object.keys(this.shift).length < 1) {
      this.shift = {
        employee: null,
        businessDate: null,
        scheduledStartAt: null,
        scheduledEndAt: null,
        notes: null,
      };
    }
    this.isDetailed =
      this.shift && this.shift.events && this.shift.events.length;
  },
  methods: {
    format,
    async updateShift() {
      this.loading = true;
      this.$emit("update", this.shiftInputObject);
      this.loading = false;
    },
    deleteEvent(event) {
      // Handle removing an event that hasn't been saved yet
      if (!event.id) {
        const idx = this.newEvents.findIndex(
          (e) => JSON.stringify(event) === JSON.stringify(e)
        );
        if (idx === -1) return true;

        this.newEvents = [...this.newEvents.slice(0, idx)];
        return true;
      }

      // Handle removing a database-persisted event
      this.$apollo.mutate({
        mutation: DELETE_SHIFT_EVENT,
        variables: {
          id: event.id,
        },
        update: (proxy) => {
          const updates = ApolloCacheUpdater({
            proxy,
            operator: "ANY",
            queriesToUpdate: [SHIFTS_TODAY],
            searchVariables: {},
            operation: {
              type: "REMOVE",
              remove: ({ data }) => {
                data = [...data];
                const shiftIndex = data.findIndex(
                  (shift) => shift.id === this.shiftId
                );
                if (shiftIndex > -1) {
                  const shift = { ...data[shiftIndex] };
                  data[shiftIndex] = {
                    ...shift,
                    events: shift.events.filter((e) => e.id !== event.id),
                  };
                }
                return data;
              },
            },
            mutationResult: { id: event.id, __typename: "ShiftEvent" },
          });
          if (updates) {
            this.triggerUpdate++;
            console.log("ShiftEvent removed", updates);
          }
        },
      });
    },
    addEvent(event) {
      this.shift.events.forEach((ev, i) => {
        if (ev.id === event.id) {
          this.shift.events = event;
          this.updatedEvents.push(event);
          return;
        }
      });
      this.newEvents.push(event);
    },
  },
};
</script>

<template>
  <v-container>
    <v-row no-gutters align="center" class="mb-1">
      <span class="font-weight-medium">Employee:</span>
      <RaiEmployeeSelect
        v-model="shift.employee"
        :employees="employees"
        show-name
      />
    </v-row>
    <v-row class="mx-0">
      <RaiDatePicker
        v-model="shift.businessDate"
        data-testid="shiftFormBusinessDateField"
        :disabled="notManagerOrIsWIWShift"
        shrink
        label="Date"
        :min="format(new Date(), 'yyyy-MM-dd')"
      />
      <RaiTimePickerText
        v-model="shift.scheduledStartAt"
        data-testid="shiftFormStartTimeField"
        class="ml-4"
        :reference-date="shift.businessDate"
        :disabled="notManagerOrIsWIWShift"
        label="Start"
      />
      <RaiTimePickerText
        v-model="shift.scheduledEndAt"
        data-testid="shiftFormEndTimeField"
        class="ml-4"
        :reference-date="shift.businessDate"
        :disabled="notManagerOrIsWIWShift"
        label="End"
      />
    </v-row>
    <v-row no-gutters>
      <v-text-field
        v-model="shift.notes"
        data-testid="shiftFormNotesField"
        name="notes"
        label="Notes"
        autocomplete="nottoday"
        :disabled="!activeEmployeeCan.seeManagerContent"
        clearable
      />
    </v-row>
    <v-row no-gutters justify="start">
      <v-checkbox v-model="isDetailed" label="Add details" />
    </v-row>
    <template v-if="isDetailed">
      <ShiftDialogAddEvent
        :reference-date="shift.businessDate"
        :min="shift.scheduledStartAt"
        :max="shift.scheduledEndAt"
        @submit="(ev) => addEvent(ev)"
      />
      <ShiftDialogEventList
        :events="computedShiftEvents"
        @delete="deleteEvent"
      />
    </template>
    <v-row class="justify-end mx-2">
      <v-btn
        text
        color="primary"
        :disabled="disabled"
        :loading="loading"
        data-testid="shiftFormSubmitBtn"
        @click="updateShift()"
        v-text="!shift.id ? 'Create Shift' : 'Update Shift'"
      ></v-btn>
    </v-row>
  </v-container>
</template>

<style></style>
